//import "../css/bootstrap.scss";
//import "../css/style.scss";
import 'bootstrap';

// Packages

import $ from 'jquery';
import { Navigation } from './navigation';
import { Slider } from './productSlider';

console.log("Hello webpack");

$(document).ready(function () {
    var menu = new Navigation();


    var openMenuBtn = $(".menu_open_link");
    var closeMenuBtn = $(".menu_close_link");

    openMenuBtn.click(() => {
        menu.openMenu();
    });

    closeMenuBtn.click(() => {
        menu.closeMenu();
    });

    if (document.getElementById("product_carosal")) {
        var slider = new Slider();

        $(".go_to_next_slide").click(() => {
            slider.plusSlide(1);
        })

        $(".go_to_prev_slide").click(() => {
            slider.plusSlide(-1);
        })
    }

    // Check if all the images are loaded and then hide the loader

    var columns = 0;

    function generateHeader(items) {
        columns = items.length;
        var header = '<thead class="thead-light"><tr>';

        for (var i = 0; i < items.length; i++) {
            //console.log(items[i]);
            header += '<th scope="col" class="center-text">' + items[i] + '</th>'
        }

        header += "</tr></thead>";

        return header;
    }

    function generateRows(items) {
        //console.log(items);

        var keys = Object.keys(items);

        //console.log(keys);

        var row = "<tbody>";
        var index = 2;

        for (var i = 0; i < keys.length; i++) {
            row += '<tr>';
            var result = items[keys[i]];

            for (var value in result) {
                //console.log("Column: "+value+" Row: "+keys[i]+" Value: "+result[value]);
                var words = result[value];

                if (words == "null" || words == null) {
                    words = "";
                }

                row += '<td class="center-text">' + words + '</td>'

            }
            row += "</tr>";
            index++;
        }

        row += "</tbody>";

        return row;
    }

    function renderSizesTable(data) {

    }

    // Swap modal title on open
    $('#exampleModalCenter').on('show.bs.modal', function (event) {
        var loader = $('.modal_loader');
        var errorNote = $('.no_sizes_avalible');
        var button = $(event.relatedTarget)
        var title = button.data('title')
        var id = button.data('id');
        var modal = $(this)
        var wrapper = $('.tyre-specs-table');

        wrapper.empty();
        loader.css("display", "block");

        var urlParams = '';

        var url = location.origin + urlParams + '/products/sizes/' + id + '/';

        loader.css("display", "block");
        $(wrapper).empty();

        console.log(url);

        fetch(url)
            .then(res => res.json())
            .then((out) => {
                var json = JSON.parse(out);
                //console.log(json);
                $(wrapper).append(generateHeader(json.columns));
                $(wrapper).append(generateRows(json.rows));
                loader.css("display", "none");
            })
            .catch(err => {
                loader.css("display", "none");
                errorNote.css("display", "block");
                throw err;
            });

        modal.find('.modal-title').text(title);
    })

    $(document).on('click', '.watch-yt-video-btn', function(e) {
        e.preventDefault();
        var frame = $(".promo_tyres_video_frame");
        var modal = $(".promo_tyres_video_modal");
        var url = $('.watch-yt-video-btn').data('url');
        frame.attr("src", url);
        modal.modal();
    });

})
