import React, {Component} from 'react';

class SearchCardComponent extends Component
{

    constructor(props) {
        super(props);
    }

    render() {

        let name = this.props.data.name,
        subheading = this.props.data.subHeading,
        thumbnail = this.props.data.tyreImage,
        url = this.props.url + "/catalogue/" + this.props.data.catalogue.slug + "/" + this.props.data.section.slug + "/products/" + this.props.data.slug;

        return(
            <li className="tyresresultcard">
                <div className="tyresresultcard__contents">
                    <div className="tyresresultcard__thumbnail">
                        <img src={thumbnail} alt=""/>
                    </div>
                    <div className="tyresresultcard__wrapper">
                        <h2>{name}</h2>
                        <h3>{subheading}</h3>
                    </div>
                </div>
                <div className="tyresresultcard__actions">
                    <ul className="tyresresultcard__actionslist">
                        <li className="tyresresultcard__actionsitem">
                            <a href={url} target="_blank" className="tyresresultcard__action--button">View Tyre Details</a>
                        </li>
                    </ul>
                </div>
            </li>
        );
    }

}

export default SearchCardComponent;
