import ReactDOM from 'react-dom';
import React from 'react';
import DealersResultsPage from "./components/pages/dealers_results.component";
import SearchbarComponent from "./components/ui/searchbar.component";
import SearchResultsComponent from "./components/pages/search_results.component";


try {
    window.$ = window.jQuery = require('jquery');
} catch(e) {}

require('./frontend/index');
require('jquery-nice-select');

$(document).ready(function() {
    $('#changeCatalogueSelect').niceSelect();
    $('#changeLocationSelect').niceSelect();
    $('#searchBarSelect').niceSelect();
})

// Render react components

if(document.getElementById("dealers_search_results") !== null) {
    const propsContainer = document.getElementById("dealers_search_results");
    const props = Object.assign({}, propsContainer.dataset);
    ReactDOM.render(<DealersResultsPage {...props} />, document.getElementById("dealers_search_results"));
}

if(document.getElementById("searchbar_component") !== null) {
    const propsContainerSearchDealers = document.getElementById("searchbar_component");
    const propsSearchDealers = Object.assign({}, propsContainerSearchDealers.dataset);
    ReactDOM.render(<SearchbarComponent {...propsSearchDealers} />, document.getElementById("searchbar_component"));
}

if(document.getElementById("tyres_search_results") !== null) {
    const propsContainerSearch = document.getElementById("tyres_search_results");
    const propsSearch = Object.assign({}, propsContainerSearch.dataset);
    ReactDOM.render(<SearchResultsComponent {...propsSearch} />, document.getElementById("tyres_search_results"));
}

var url = window.location.pathname.split('/');
var baseURL = $("#share_btn").data("url"),
    subject = $("#share_btn").data("subject");

document.addEventListener('slideChanged', function(e) {

    if(url.length > 5) {
        url.pop();
    }

    url[0] = baseURL;
    url.push(e.detail.slug);

    var clean = 'mailto:?subject=' + subject + '&body=' + url.join("/");

    $("#share_btn").attr("href", encodeURI(clean));
    $("#share_btn_2").attr("href", encodeURI(clean));
    $("#share_btn_3").attr("href", encodeURI(clean));
});
