//import $ from 'jquery';

export class Navigation {

    constructor() {
        this.menu = $(".navigation_menu_container");
    }

    openMenu() {

        this.menu.addClass("navigation_menu_open");

        //menu.addClass("navigation_menu_open")

    }

    closeMenu() {
        this.menu.removeClass("navigation_menu_open");
    }

}
