import React, {Component} from 'react';
import DealersResultCard from "../ui/dealers_card.component";
import SearchbarComponent from "../ui/searchbar.component";
import NoResultsComponent from "../ui/noresults.component";

// Components

class DealersResultsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            results: []
        };
    }

    componentDidMount() {
        let results = JSON.parse(this.props.results);

        if(results !== null && results !== false && results !== "undefined") {
            this.setState({
                results: results
            });
        }
    }

    dealerRender()
    {
        return (
            <div className="col-md-10">
                <div className="dealersresults__headline">
                    <h2>Showing Results For: {this.props.region}</h2>
                    <button className="dealersresults__button" data-toggle="modal" data-target="#dealersSearchModal">Search Again <i className="fas fa-search"></i></button>
                </div>
                <div className="dealersresults__divider"></div>
                <ul className="dealersresults__grid">
                    {this.state.results.map((item, key) => (
                        <DealersResultCard
                            data={item}
                            key={key}
                        />
                    ))}
                </ul>
            </div>
        );
    }

    renderNoResults()
    {
        return(<div className="col-md-10">
            <div className="dealersresults__headline">
                <h2>Showing Results For: {this.props.region}</h2>
                <button className="dealersresults__button" data-toggle="modal" data-target="#dealersSearchModal">Search Again <i className="fas fa-search"></i></button>
            </div>
            <div className="tyresresults__divider"></div>
            <ul className="tyresresults__grid">
                <NoResultsComponent />
            </ul>
        </div>);
    }

    render() {

        return(
            <div className="row align-items-center column-10">
                <div className="col-md-1"></div>
                {this.state.results.length > 0 ? this.dealerRender() : this.renderNoResults()}
                <div className="col-md-1"></div>
            </div>
        );
    }

}

export default DealersResultsPage;
