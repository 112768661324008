export class Slider {

    constructor(sliderIndex) {
        this.sliderIndex = 1;

        if(this.getProduct() !== false) {
            this.sliderIndex = this.getProduct();
        }

        this.showSlides(sliderIndex);

        this.previousURL = $(".product_slides").data('previous');
        this.nextURL = $(".product_slides").data('next');
    }

    getProduct() {
        var scroll = $(".product_slides").data('scroll');

        if(scroll) {

            var slides = document.getElementsByClassName('product_slide');
            var returnVal = 0;

            for(var i = 0; i < slides.length; i++) {
                //console.log(slides[i].dataset.id, i);
                if(slides[i].dataset.id == scroll) {
                    returnVal = i + 1;
                }
            }

            return parseInt(returnVal);
        }

        return false;
    }

    plusSlide(n) {
        this.showSlides((this.sliderIndex += n));
    }

    showSlides(n) {

        var i;
        var slides = document.getElementsByClassName('product_slide'); // Get the no of slides

        if(n > slides.length)
        {
            window.location = this.nextURL;
            //this.sliderIndex = 1;

            // Completed
        }

        if(n == 0) {
            window.location = this.previousURL;
        }

        if(n < 1) {
            this.sliderIndex = slides.length;
        }

        for(i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }

        var slug = slides[this.sliderIndex-1].dataset.slug;
        var event = new CustomEvent('slideChanged', {detail: {slug: slug}});

        slides[this.sliderIndex-1].style.display = "block";
        var warranty = slides[this.sliderIndex-1].getAttribute('data-warranty');

        switch(warranty) {
            case "0":
            $(".product_warranty_sticker").css("display", "none");
            break;
            case "1":
            $(".product_warranty_sticker").fadeIn();
            break;
        }

        document.dispatchEvent(event);

    }

}
