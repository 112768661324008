import React, {Component} from 'react';
import DealersResultCard from "../ui/dealers_card.component";
import SearchCardComponent from "../ui/search_card.component";
import SearchbarComponent from "../ui/searchbar.component";
import NoResultsComponent from "../ui/noresults.component";

// Components

class SearchResultsComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            results: [],
            selected: "undefined"
        };
    }

    componentDidMount() {
        console.log(this.props.productsurl);

        let results = JSON.parse(this.props.results);

        if(results !== null && results !== false && results !== "undefined") {
            this.setState({
                results: results
            });
        }
    }

    renderResults() {
        return(<div className="col-md-10">
            <div className="tyresresults__headline">
                <form method="get" action={this.props.searchformurl}>
                    <SearchbarComponent catalogues={this.props.region} query={this.props.query} selected={this.props.selected} />
                </form>
            </div>
            <div className="tyresresults__divider"></div>
            <ul className="tyresresults__grid">
                {this.state.results.map((item, key) => (
                    <SearchCardComponent key={key} data={item} url={this.props.productsurl} />
                ))}
            </ul>
        </div>);
    }

    noResultsRender()
    {
        return(<div className="col-md-10">
            <div className="tyresresults__headline">
                <form method="get" action={this.props.searchformurl}>
                    <SearchbarComponent catalogues={this.props.region} query={this.props.query} selected={this.props.selected} />
                </form>
            </div>
            <div className="tyresresults__divider"></div>
            <ul className="tyresresults__grid">
                <NoResultsComponent />
            </ul>
        </div>);
    }

    render() {

        return(
            <div className="row align-items-center column-10">
                <div className="col-md-1"></div>
                {this.state.results.length > 0 ? this.renderResults() : this.noResultsRender()}
                <div className="col-md-1"></div>
            </div>
        );
    }

}

export default SearchResultsComponent;
