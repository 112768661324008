import React, {Component} from 'react';

class DealersResultCard extends Component
{

    constructor(props) {
        super(props);
    }

    render() {

        let email = this.props.data.email,
            name = this.props.data.name,
            address = this.props.data.street + ", " + this.props.data.city + ", " + this.props.data.region + ", " + this.props.data.postcode;

        let phone = null;

        if (this.props.data.phone) {
            phone = this.props.data.phone.replace(/[^A-Z0-9]/ig, "")
        }

        return(
            <li className="dealersresultcard">
                <div className="dealersresultcard__name">
                    <h2>{name}</h2>
                </div>
                <div className="dealersresultcard__directions">
                    <ul className="dealersresultcard__details">
                        <li className="dealersresultcard__detailsitem"><i className="fas fa-map-marker-alt"></i> {address}</li>
                        {phone && <li className="dealersresultcard__detailsitem"><i className="fas fa-phone"></i> <a href={'tel:' + phone} target="_blank">{phone}</a></li> }
                        <li className="dealersresultcard__detailsitem"><i className="fas fa-envelope"></i> <a href={'mailto:' + email} target="_blank">{email}</a></li>
                    </ul>
                </div>
                <div className="dealersresultcard__actions">
                    <ul className="dealersresultcard__actionslist">
                        {phone &&
                            <li className="dealersresultcard__action">
                                <a href="#" className="dealersresultcard__action--button">Call <i
                                    className="fas fa-phone"></i></a>
                            </li>
                        }
                        <li className="dealersresultcard__action">
                            <a href="#" className="dealersresultcard__action--button">Email <i
                                className="fas fa-envelope"></i></a>
                        </li>
                    </ul>
                </div>
            </li>
        );
    }

}

export default DealersResultCard;
