import React, {Component} from 'react';

class SearchbarComponent extends Component
{

    constructor(props) {
        super(props);

        this.state = {
            catalogues: [],
            query: '',
            selectedCatalogue: '-1'
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        var query = '';
        var selectedItem = null;

        if(this.props.query !== "undefined") {
            query = this.props.query;
        }

        if(this.props.selected !== "undefined" && this.props.selected !== null) {
            selectedItem = this.props.selected;
        }

        this.setState({
            catalogues: JSON.parse(this.props.catalogues),
            query: query,
            selectedCatalogue: selectedItem
        })
    }

    handleChange(e) {
        this.setState({
            query: e.target.value
        });
    }

    render() {

        return (
            <div className="searchbar__inner">
                <div className="searchbar__select">
                    <select name="catalogue" id="searchBarSelect" className="searchbar__selectinput">
                        {this.state.catalogues.map((item, key) => (
                            <option value={item.id} key={key} selected={parseInt(this.state.selectedCatalogue) === parseInt(item.id)}>{item.name}</option>
                        ))}
                    </select>
                </div>
                <div className="searchbar__input">
                    <input type="text" name="query" className="searchbar__textinput" placeholder="Search for tyre by name..." value={this.state.query} onChange={this.handleChange} />
                    <button type="submit" className="searchbar__searchbtn"><i className="fas fa-search"></i></button>
                </div>
            </div>
        );
    }

}

export default SearchbarComponent;
