import React, {Component} from 'react';

class NoResultsComponent extends Component
{
    render() {
        return(
            <div className="noresults">
                <h1>No Results Found</h1>
            </div>
        );
    }
}

export default NoResultsComponent;
